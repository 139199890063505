import { Link } from '@inertiajs/react'
import Avatar from "../Avatar";
import { arrayToString } from "@/utils";

export default function CommunityCard({community}) { 
    let { id, name, region, cover_card, member_count, championship_count, event_count } = community

    return (
        <Link href={route('communities.show', id)} className="card bg-base-300 shadow-xl hover:-translate-y-2 duration-200 transition-all">
            <figure className="border-b-2 aspect-video">
                <img src={cover_card} alt="Community"/>
            </figure>
            
            <div className="card-body p-5 items-center text-center">
                <div className="-mt-16 mb-4">
                    <Avatar width="w-24" url={community.avatar} className="drop-shadow-lg" />
                </div>

                <div className="flex h-14 items-center">
                    <h2 className="card-title block uppercase text-ellipsis line-clamp-2">{name}</h2>
                </div>

                <div className="flex flex-col my-4">
                    <span className="material-icons-outlined text-primary text-5xl">language</span>
                    <span>{region && arrayToString(region)}</span>
                </div>

                <div className="w-10/12 flex justify-between my-4">
                    <div className="flex flex-col">
                        <div className="tooltip" data-tip="Members">
                            <span className="material-icons-outlined text-5xl text-gray-400 tooltip">groups</span>
                        </div>
                        <span className="text-xl font-bold">{member_count}</span>
                    </div>
                    <div className="border-r-2 border-gray-400"></div>
                    <div className="flex flex-col">
                        <div className="tooltip" data-tip="Events">
                            <span className="material-icons-outlined text-5xl text-gray-400">calendar_month</span>
                        </div>
                        <span className="text-xl font-bold">{event_count}</span>
                    </div>
                    <div className="border-r-2 border-gray-400"></div>
                    <div className="flex flex-col">
                        <div className="tooltip" data-tip="Championships">
                            <span className="material-icons-outlined text-5xl text-gray-400">emoji_events</span>
                        </div>
                        <span className="text-xl font-bold">{championship_count}</span>
                    </div>
                </div>

                <div className="card-actions w-full">
                    <button className="btn btn-primary btn-block">
                        View Community
                    </button>
                </div>
            </div>
        </Link>
    );
}
